// blue gradient background for start page
$gradient-1: radial-gradient(18% 28% at 18% 71%, #ffffff59 6%, #073aff00 100%),
  radial-gradient(70% 53% at 36% 76%, #54a8b7ff 0%, #1036c521 100%),
  radial-gradient(42% 53% at 15% 94%, #ffffffff 7%, #073aff00 100%),
  radial-gradient(42% 53% at 34% 72%, #ffffffff 7%, #073aff00 100%),
  radial-gradient(18% 28% at 35% 87%, #ffffffff 7%, #073aff00 100%),
  radial-gradient(31% 43% at 7% 98%, #ffffffff 24%, #073aff00 100%),
  radial-gradient(21% 37% at 72% 23%, #6dffed9c 24%, #073aff00 100%),
  radial-gradient(35% 56% at 91% 74%, #8a4ffff5 9%, #073aff00 100%),
  radial-gradient(74% 86% at 67% 38%, #6dccfff5 24%, #073aff00 100%),
  linear-gradient(125deg, #4eb5ffff 1%, #fc0000ff 100%);

$gradient-2: radial-gradient(18% 28% at 18% 71%, #FFFFFF82 6%, #073AFF1A 100%),
  radial-gradient(70% 53% at 36% 76%, #73F2FFFF 0%, #073AFF00 100%),
  radial-gradient(42% 53% at 15% 94%, #FFFFFFFF 7%, #073AFF00 100%),
  radial-gradient(42% 53% at 34% 72%, #FFFFFFFF 7%, #073AFF00 100%),
  radial-gradient(18% 28% at 35% 87%, #FFFFFFFF 7%, #073AFF00 100%),
  radial-gradient(31% 43% at 7% 98%, #FFFFFFFF 24%, #073AFF00 100%),
  radial-gradient(21% 37% at 72% 23%, #6DFFD09C 24%, #073AFF00 100%),
  radial-gradient(35% 56% at 91% 74%, #8A4FFFF5 9%, #073AFF00 100%),
  radial-gradient(74% 86% at 67% 38%, #6DFFAEF5 24%, #073AFF00 100%),
  linear-gradient(125deg, #4EB5FFFF 1%, #4C00FCFF 100%);

  // https://colorgradient.dev/gradient-generator/

.gradient-home {
  background-size: 100% 100%;
  background-position: 0px 0px, 0px 0px, 0px 0px, 0px 0px, 0px 0px, 0px 0px, 0px 0px, 0px 0px, 0px 0px, 0px 0px;
  background-image: $gradient-1;
}

.gradient-feature {
  background-size: 100% 100%;
  background-position: 0px 0px, 0px 0px, 0px 0px, 0px 0px, 0px 0px, 0px 0px, 0px 0px, 0px 0px, 0px 0px, 0px 0px;
  background-image: $gradient-2;
}

.background-blur {
  background-color: rgba(var(--#{$prefix}white-rgb), 0.8);
  backdrop-filter: saturate(200%) blur(40px);
}

.border-bottom-dotted {
  border-bottom-style: dotted;
}

.card-cover.cover-200 {
  background-position: center;
  background-size: 200%;
}