#utility-navigation {
  min-height: 90px;
  max-height: 90px;

  a.hover-item {
    &:hover {
      filter: invert(100%) opacity(50%);
    }
  }
}

@include media-breakpoint-up(md) {
  #utility-navigation {
    width: 140px;

    .language-selector {
      width: 60px;
    }
  }
}