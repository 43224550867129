.page-header {
  padding: 0;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: 50%;
  border-bottom-left-radius: 3rem;
  border-bottom-right-radius: 3rem;
}