#sub-navigation {
  z-index: 9;
  min-height: 90px;
  max-height: 90px;
  margin-top: 90px;
  margin-right: 1rem;
  margin-left: 1rem;

  .sub-heading {
    margin-top: auto;
    margin-bottom: auto;
    min-height: 90px;
  }

  .icon-tabler {
    color: white;
    width: 42px;
    height: 42px;
    stroke-width: 2.5;
  }
  .nav-item {
    text-transform: uppercase;

    > .nav-link {
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &.dropdown {
      .dropdown-item {
        background: none;
        background-image: none;
      }

      &.dropdown-mega {
        position: static;

        // TODO: add style for mobile viewport
        .dropdown-menu {
          width: 90%;
          top: auto;
          left: 5%;
        }
      }
    }
  }

  @include media-breakpoint-down(xl) {
    .nav-item {
      &.dropdown {
        &.dropdown-mega {
          .dropdown-menu {
            width: 100%;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(xl) {
    .navbar-collapse .navbar-nav {
      @include lin-gradient(45deg, $gray-800, $gray-900);
    }
  }
}

@include media-breakpoint-up(md) {
  #sub-navigation {
    margin-left: 455px;
    margin-top: 0.8rem;
    margin-right: 0;
    width: calc(100% - 470px);

    .sub-heading {
      margin-top: auto;
      margin-bottom: auto;
      min-height: 80px;
    }
  }
}

.navbar-toggler {
  border: none;
  padding: 0;
  outline: none;

  &:focus {
    box-shadow: none;
  }
}
