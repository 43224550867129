// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

// scss-docs-start gray-color-variables
$black:     #000;
$gray-900:  #434c59; // GWDG Gray
$gray-800:  lighten($gray-900, 10%);
$gray-700:  lighten($gray-900, 20%);
$gray-600:  lighten($gray-900, 30%);
$gray-500:  lighten($gray-900, 40%);
$gray-400:  lighten($gray-900, 50%);
$gray-300:  lighten($gray-900, 60%);
$gray-200:  lighten($gray-900, 70%);
$gray-100:  lighten($gray-900, 80%);
$white:    #fff;
// scss-docs-end gray-color-variables

// fusv-disable
// scss-docs-start gray-colors-map
$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
);
// scss-docs-end gray-colors-map
// fusv-enable

// scss-docs-start color-variables
$blue:    #009ee0; // GWDG Blue
$indigo:  #6610f2;
$purple:  #9887e6; // GWDG Purple
$pink:    #d63384;
$red:     #e96354; // GWDG Red
$orange:  #fd7e14;
$yellow:  #f9d856; // GWDG Yellow
$green:   #7bce5c; // GWDG Green
$teal:    #44b0bd; // GWDG Teal
$cyan:    #0dcaf0;
// scss-docs-end color-variables

// scss-docs-start colors-map
$colors: (
  "blue": $blue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "black": $black,
  "white": $white,
  "gray": $gray-600,
  "gray-dark": $gray-800
);
// scss-docs-end colors-map

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 4.5;

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark: $black;
$color-contrast-light: $white;

// fusv-disable
$blue-100: tint-color($blue, 80%);
$blue-200: tint-color($blue, 60%);
$blue-300: tint-color($blue, 40%);
$blue-400: tint-color($blue, 20%);
$blue-500: $blue;
$blue-600: shade-color($blue, 20%);
$blue-700: shade-color($blue, 40%);
$blue-800: shade-color($blue, 60%);
$blue-900: shade-color($blue, 80%);

$indigo-100: tint-color($indigo, 80%);
$indigo-200: tint-color($indigo, 60%);
$indigo-300: tint-color($indigo, 40%);
$indigo-400: tint-color($indigo, 20%);
$indigo-500: $indigo;
$indigo-600: shade-color($indigo, 20%);
$indigo-700: shade-color($indigo, 40%);
$indigo-800: shade-color($indigo, 60%);
$indigo-900: shade-color($indigo, 80%);

$purple-100: tint-color($purple, 80%);
$purple-200: tint-color($purple, 60%);
$purple-300: tint-color($purple, 40%);
$purple-400: tint-color($purple, 20%);
$purple-500: $purple;
$purple-600: shade-color($purple, 20%);
$purple-700: shade-color($purple, 40%);
$purple-800: shade-color($purple, 60%);
$purple-900: shade-color($purple, 80%);

$pink-100: tint-color($pink, 80%);
$pink-200: tint-color($pink, 60%);
$pink-300: tint-color($pink, 40%);
$pink-400: tint-color($pink, 20%);
$pink-500: $pink;
$pink-600: shade-color($pink, 20%);
$pink-700: shade-color($pink, 40%);
$pink-800: shade-color($pink, 60%);
$pink-900: shade-color($pink, 80%);

$red-100: tint-color($red, 80%);
$red-200: tint-color($red, 60%);
$red-300: tint-color($red, 40%);
$red-400: tint-color($red, 20%);
$red-500: $red;
$red-600: shade-color($red, 20%);
$red-700: shade-color($red, 40%);
$red-800: shade-color($red, 60%);
$red-900: shade-color($red, 80%);

$orange-100: tint-color($orange, 80%);
$orange-200: tint-color($orange, 60%);
$orange-300: tint-color($orange, 40%);
$orange-400: tint-color($orange, 20%);
$orange-500: $orange;
$orange-600: shade-color($orange, 20%);
$orange-700: shade-color($orange, 40%);
$orange-800: shade-color($orange, 60%);
$orange-900: shade-color($orange, 80%);

$yellow-100: tint-color($yellow, 80%);
$yellow-200: tint-color($yellow, 60%);
$yellow-300: tint-color($yellow, 40%);
$yellow-400: tint-color($yellow, 20%);
$yellow-500: $yellow;
$yellow-600: shade-color($yellow, 20%);
$yellow-700: shade-color($yellow, 40%);
$yellow-800: shade-color($yellow, 60%);
$yellow-900: shade-color($yellow, 80%);

$green-100: tint-color($green, 80%);
$green-200: tint-color($green, 60%);
$green-300: tint-color($green, 40%);
$green-400: tint-color($green, 20%);
$green-500: $green;
$green-600: shade-color($green, 20%);
$green-700: shade-color($green, 40%);
$green-800: shade-color($green, 60%);
$green-900: shade-color($green, 80%);

$teal-100: tint-color($teal, 80%);
$teal-200: tint-color($teal, 60%);
$teal-300: tint-color($teal, 40%);
$teal-400: tint-color($teal, 20%);
$teal-500: $teal;
$teal-600: shade-color($teal, 20%);
$teal-700: shade-color($teal, 40%);
$teal-800: shade-color($teal, 60%);
$teal-900: shade-color($teal, 80%);

$cyan-100: tint-color($cyan, 80%);
$cyan-200: tint-color($cyan, 60%);
$cyan-300: tint-color($cyan, 40%);
$cyan-400: tint-color($cyan, 20%);
$cyan-500: $cyan;
$cyan-600: shade-color($cyan, 20%);
$cyan-700: shade-color($cyan, 40%);
$cyan-800: shade-color($cyan, 60%);
$cyan-900: shade-color($cyan, 80%);

$blues: (
  "blue-100": $blue-100,
  "blue-200": $blue-200,
  "blue-300": $blue-300,
  "blue-400": $blue-400,
  "blue-500": $blue-500,
  "blue-600": $blue-600,
  "blue-700": $blue-700,
  "blue-800": $blue-800,
  "blue-900": $blue-900
);

$indigos: (
  "indigo-100": $indigo-100,
  "indigo-200": $indigo-200,
  "indigo-300": $indigo-300,
  "indigo-400": $indigo-400,
  "indigo-500": $indigo-500,
  "indigo-600": $indigo-600,
  "indigo-700": $indigo-700,
  "indigo-800": $indigo-800,
  "indigo-900": $indigo-900
);

$purples: (
  "purple-100": $purple-100,
  "purple-200": $purple-200,
  "purple-300": $purple-300,
  "purple-400": $purple-400,
  "purple-500": $purple-500,
  "purple-600": $purple-600,
  "purple-700": $purple-700,
  "purple-800": $purple-800,
  "purple-900": $purple-900
);

$pinks: (
  "pink-100": $pink-100,
  "pink-200": $pink-200,
  "pink-300": $pink-300,
  "pink-400": $pink-400,
  "pink-500": $pink-500,
  "pink-600": $pink-600,
  "pink-700": $pink-700,
  "pink-800": $pink-800,
  "pink-900": $pink-900
);

$reds: (
  "red-100": $red-100,
  "red-200": $red-200,
  "red-300": $red-300,
  "red-400": $red-400,
  "red-500": $red-500,
  "red-600": $red-600,
  "red-700": $red-700,
  "red-800": $red-800,
  "red-900": $red-900
);

$oranges: (
  "orange-100": $orange-100,
  "orange-200": $orange-200,
  "orange-300": $orange-300,
  "orange-400": $orange-400,
  "orange-500": $orange-500,
  "orange-600": $orange-600,
  "orange-700": $orange-700,
  "orange-800": $orange-800,
  "orange-900": $orange-900
);

$yellows: (
  "yellow-100": $yellow-100,
  "yellow-200": $yellow-200,
  "yellow-300": $yellow-300,
  "yellow-400": $yellow-400,
  "yellow-500": $yellow-500,
  "yellow-600": $yellow-600,
  "yellow-700": $yellow-700,
  "yellow-800": $yellow-800,
  "yellow-900": $yellow-900
);

$greens: (
  "green-100": $green-100,
  "green-200": $green-200,
  "green-300": $green-300,
  "green-400": $green-400,
  "green-500": $green-500,
  "green-600": $green-600,
  "green-700": $green-700,
  "green-800": $green-800,
  "green-900": $green-900
);

$teals: (
  "teal-100": $teal-100,
  "teal-200": $teal-200,
  "teal-300": $teal-300,
  "teal-400": $teal-400,
  "teal-500": $teal-500,
  "teal-600": $teal-600,
  "teal-700": $teal-700,
  "teal-800": $teal-800,
  "teal-900": $teal-900
);

$cyans: (
  "cyan-100": $cyan-100,
  "cyan-200": $cyan-200,
  "cyan-300": $cyan-300,
  "cyan-400": $cyan-400,
  "cyan-500": $cyan-500,
  "cyan-600": $cyan-600,
  "cyan-700": $cyan-700,
  "cyan-800": $cyan-800,
  "cyan-900": $cyan-900
);
// fusv-enable

// scss-docs-start theme-color-variables
$primary: $blue;
$secondary: $gray-500;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-900;
// scss-docs-end theme-color-variables

// scss-docs-start theme-colors-map
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark
);
// scss-docs-end theme-colors-map

$primary-gradient: 135deg, $blue, $blue-400;
$secondary-gradient: 135deg, $gray-500, $gray-900;
$success-gradient: 135deg, $green, $green-700;
$info-gradient: 135deg, $cyan, $cyan-300;
$warning-gradient: 135deg, $yellow, $red-300;
$danger-gradient: 135deg, $red, $red-600;
$light-gradient: 135deg, $gray-100, $gray-500;
$dark-gradient: 135deg, $gray-800, $gray-900;

$theme-gradients: (
  "primary": $primary-gradient,
  "secondary": $secondary-gradient,
  "success": $success-gradient,
  "info": $info-gradient,
  "warning": $warning-gradient,
  "danger": $danger-gradient,
  "light": $light-gradient,
  "dark": $dark-gradient
);

// scss-docs-start theme-text-variables
$primary-text-emphasis: shade-color($primary, 60%);
$secondary-text-emphasis: shade-color($secondary, 60%);
$success-text-emphasis: shade-color($success, 60%);
$info-text-emphasis: shade-color($info, 60%);
$warning-text-emphasis: shade-color($warning, 60%);
$danger-text-emphasis: shade-color($danger, 60%);
$light-text-emphasis: $gray-700;
$dark-text-emphasis: $gray-700;
// scss-docs-end theme-text-variables

// scss-docs-start theme-bg-subtle-variables
$primary-bg-subtle: tint-color($primary, 80%);
$secondary-bg-subtle: tint-color($secondary, 80%);
$success-bg-subtle: tint-color($success, 80%);
$info-bg-subtle: tint-color($info, 80%);
$warning-bg-subtle: tint-color($warning, 80%);
$danger-bg-subtle: tint-color($danger, 80%);
$light-bg-subtle: mix($gray-100, $white);
$dark-bg-subtle: $gray-400;
// scss-docs-end theme-bg-subtle-variables

// scss-docs-start theme-border-subtle-variables
$primary-border-subtle: tint-color($primary, 60%);
$secondary-border-subtle: tint-color($secondary, 60%);
$success-border-subtle: tint-color($success, 60%);
$info-border-subtle: tint-color($info, 60%);
$warning-border-subtle: tint-color($warning, 60%);
$danger-border-subtle: tint-color($danger, 60%);
$light-border-subtle: $gray-200;
$dark-border-subtle: $gray-500;
// scss-docs-end theme-border-subtle-variables

// Characters which are escaped by the escape-svg function
$escaped-characters: (("<", "%3c"), (">", "%3e"), ("#", "%23"), ("(", "%28"), (")", "%29"));

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: true;
$enable-rounded: true;
$enable-shadows: true;
$enable-gradients: true;
$enable-transitions: true;
$enable-reduced-motion: true;
$enable-smooth-scroll: true;
$enable-grid-classes: true;
$enable-container-classes: true;
$enable-cssgrid: false;
$enable-button-pointers: true;
$enable-rfs: true;
$enable-validation-icons: true;
$enable-negative-margins: true;
$enable-deprecation-messages: true;
$enable-important-utilities: true;

$enable-dark-mode: true;
$color-mode-type: data; // `data` or `media-query`

// Prefix for :root CSS variables

$variable-prefix: bs-; // Deprecated in v5.2.0 for the shorter `$prefix`
$prefix: $variable-prefix;

// Gradient
//
// The gradient which is added to components if `$enable-gradients` is `true`
// This gradient is also added to elements with `.bg-gradient`
// scss-docs-start variable-gradient
$gradient: linear-gradient(180deg, rgba($white, 0.15), rgba($white, 0));
// scss-docs-end variable-gradient

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// scss-docs-start spacer-variables-maps
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3
);
// scss-docs-end spacer-variables-maps

// Position
//
// Define the edge positioning anchors of the position utilities.

// scss-docs-start position-map
$position-values: (
  0: 0,
  50: 50%,
  100: 100%
);
// scss-docs-end position-map

// Body
//
// Settings for the `<body>` element.

$body-bg: $gray-300;
$body-color: darken($gray-900, 20%);
$body-text-align: null;

$body-secondary-color: rgba($body-color, 0.75);
$body-secondary-bg: $gray-200;

$body-tertiary-color: rgba($body-color, 0.5);
$body-tertiary-bg: $gray-100;

$body-emphasis-color: $black;

// Links
//
// Style anchor elements.

$link-color: darken($primary, 10%);
$link-decoration: underline;
$link-shade-percentage: 20%;
$link-hover-color: shift-color($link-color, $link-shade-percentage);
$link-hover-decoration: underline;

$stretched-link-pseudo-element: after;
$stretched-link-z-index: 1;

// Icon links
// scss-docs-start icon-link-variables
$icon-link-gap: 0.375rem;
$icon-link-underline-offset: 0.25em;
$icon-link-icon-size: 1em;
$icon-link-icon-transition: 0.2s ease-in-out transform;
$icon-link-icon-transform: translate3d(0.25em, 0, 0);
// scss-docs-end icon-link-variables

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom: 1.5rem;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// scss-docs-start grid-breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);
// scss-docs-end grid-breakpoints

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// scss-docs-start container-max-widths
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
);
// scss-docs-end container-max-widths

@include _assert-ascending($container-max-widths, "$container-max-widths");

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12;
$grid-gutter-width: 1.5rem;
$grid-row-columns: 6;

// Container padding

$container-padding-x: $grid-gutter-width;

// Components
//
// Define common padding and border radius sizes and more.

// scss-docs-start border-variables
$border-width: 1px;
$border-widths: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px
);

$border-style: solid;
$border-color: $gray-300;
$border-color-translucent: rgba($black, 0.175);
// scss-docs-end border-variables

// scss-docs-start border-radius-variables
$border-radius: 0.375rem;
$border-radius-sm: 0.25rem;
$border-radius-lg: 0.5rem;
$border-radius-xl: 1rem;
$border-radius-2xl: 2rem;
$border-radius-pill: 50rem;
// scss-docs-end border-radius-variables

// scss-docs-start box-shadow-variables
// $box-shadow: 0px 0.8px 2.2px rgba(0, 0, 0, 0.02), 0px 2px 5.3px rgba(0, 0, 0, 0.028),
//  0px 3.8px 10px rgba(0, 0, 0, 0.035), 0px 6.7px 17.9px rgba(0, 0, 0, 0.042), 0px 12.5px 33.4px rgba(0, 0, 0, 0.05),
//  0px 30px 80px rgba(0, 0, 0, 0.07);

$box-shadow: 0px 0.6px 1.2px rgba(0, 0, 0, 0.011), 0px 1.4px 2.7px rgba(0, 0, 0, 0.016),
  0px 2.3px 4.6px rgba(0, 0, 0, 0.019), 0px 3.5px 6.9px rgba(0, 0, 0, 0.022), 0px 5px 10px rgba(0, 0, 0, 0.025),
  0px 7.1px 14.2px rgba(0, 0, 0, 0.028), 0px 10.1px 20.1px rgba(0, 0, 0, 0.031), 0px 14.6px 29.2px rgba(0, 0, 0, 0.034),
  0px 22.5px 45px rgba(0, 0, 0, 0.039), 0px 40px 80px rgba(0, 0, 0, 0.05);
$box-shadow-sm: 0px 0.3px 0.6px rgba(0, 0, 0, 0.011), 0px 0.7px 1.4px rgba(0, 0, 0, 0.016),
  0px 1.2px 2.3px rgba(0, 0, 0, 0.019), 0px 1.7px 3.5px rgba(0, 0, 0, 0.022), 0px 2.5px 5px rgba(0, 0, 0, 0.025),
  0px 3.5px 7.1px rgba(0, 0, 0, 0.028), 0px 5px 10.1px rgba(0, 0, 0, 0.031), 0px 7.3px 14.6px rgba(0, 0, 0, 0.034),
  0px 11.3px 22.5px rgba(0, 0, 0, 0.039), 0px 20px 40px rgba(0, 0, 0, 0.05);
$box-shadow-lg: 0px 1.2px 1.6px rgba(0, 0, 0, 0.013), 0px 2.7px 3.4px rgba(0, 0, 0, 0.019),
  0px 4.6px 5.8px rgba(0, 0, 0, 0.023), 0px 6.9px 8.7px rgba(0, 0, 0, 0.027), 0px 10px 12.5px rgba(0, 0, 0, 0.03),
  0px 14.2px 17.7px rgba(0, 0, 0, 0.033), 0px 20.1px 25.1px rgba(0, 0, 0, 0.037), 0px 29.2px 36.5px rgba(0, 0, 0, 0.041),
  0px 45px 56.3px rgba(0, 0, 0, 0.047), 0px 80px 100px rgba(0, 0, 0, 0.06);
$box-shadow-inset: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
// scss-docs-end box-shadow-variables

$component-active-color: $white;
$component-active-bg: $primary;

// scss-docs-start focus-ring-variables
$focus-ring-width: 0.25rem;
$focus-ring-opacity: 0.25;
$focus-ring-color: rgba($primary, $focus-ring-opacity);
$focus-ring-blur: 0;
$focus-ring-box-shadow: 0 0 $focus-ring-blur $focus-ring-width $focus-ring-color;
// scss-docs-end focus-ring-variables

// scss-docs-start caret-variables
$caret-width: 0.3em;
$caret-vertical-align: $caret-width * 0.85;
$caret-spacing: $caret-width * 0.85;
// scss-docs-end caret-variables

$transition-base: all 0.2s ease-in-out;
$transition-fade: opacity 0.15s linear;
// scss-docs-start collapse-transition
$transition-collapse: height 0.35s ease;
$transition-collapse-width: width 0.35s ease;
// scss-docs-end collapse-transition

// stylelint-disable function-disallowed-list
// scss-docs-start aspect-ratios
$aspect-ratios: (
  "1x1": 100%,
  "4x3": calc(3 / 4 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "21x9": calc(9 / 21 * 100%)
);
// scss-docs-end aspect-ratios
// stylelint-enable function-disallowed-list

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// scss-docs-start font-variables
// stylelint-disable value-keyword-case
$font-family-sans-serif: "Roboto", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans",
  "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
// stylelint-enable value-keyword-case
$font-family-base: var(--#{$prefix}font-sans-serif);
$font-family-code: var(--#{$prefix}font-monospace);

// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
$font-size-root: null;
$font-size-base: 1.125rem; // Assumes the browser default, typically `16px`
$font-size-sm: $font-size-base * 0.75;
$font-size-lg: $font-size-base * 1.5;

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-bolder: bolder;

$font-weight-base: $font-weight-normal;

$line-height-base: 1.5;
$line-height-sm: 1.25;
$line-height-lg: 2.25;

$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;
// scss-docs-end font-variables

// scss-docs-start font-sizes
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size
);
// scss-docs-end font-sizes

// scss-docs-start headings-variables
$headings-margin-bottom: $spacer * 0.75;
$headings-font-family: null;
$headings-font-style: null;
$headings-font-weight: null;
$headings-line-height: 1.2;
$headings-color: inherit;
// scss-docs-end headings-variables

// scss-docs-start display-headings
$display-font-sizes: (
  1: 6.75rem,
  2: 5.5rem,
  3: 4.25rem,
  4: 3.5rem,
  5: 3rem,
  6: 2.55rem
);

$display-font-family: null;
$display-font-style: null;
$display-font-weight: null;
$display-line-height: $headings-line-height;
// scss-docs-end display-headings

// scss-docs-start type-variables
$lead-font-size: $font-size-base * 1.25;
$lead-font-weight: 300;

$small-font-size: 0.875em;

$sub-sup-font-size: 0.75em;

$text-muted: var(--#{$prefix}secondary-color);

$initialism-font-size: $small-font-size;

$blockquote-margin-y: $spacer;
$blockquote-font-size: $font-size-base * 1.25;
$blockquote-footer-color: $gray-600;
$blockquote-footer-font-size: $small-font-size;

$hr-margin-y: $spacer;
$hr-color: inherit;

// fusv-disable
$hr-bg-color: null; // Deprecated in v5.2.0
$hr-height: null; // Deprecated in v5.2.0
// fusv-enable

$hr-border-color: null; // Allows for inherited colors
$hr-border-width: var(--#{$prefix}border-width);
$hr-opacity: 0.25;

$legend-margin-bottom: 0.5rem;
$legend-font-size: 1.5rem;
$legend-font-weight: null;

$dt-font-weight: $font-weight-bold;

$list-inline-padding: 0.5rem;

$mark-padding: 0.1875em;
$mark-bg: $yellow-100;
// scss-docs-end type-variables

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

// scss-docs-start table-variables
$table-cell-padding-y: 0.5rem;
$table-cell-padding-x: 0.5rem;
$table-cell-padding-y-sm: 0.25rem;
$table-cell-padding-x-sm: 0.25rem;

$table-cell-vertical-align: top;

$table-color: var(--#{$prefix}body-color);
$table-bg: var(--#{$prefix}body-bg);
$table-accent-bg: transparent;

$table-th-font-weight: null;

$table-striped-color: $table-color;
$table-striped-bg-factor: 0.05;
$table-striped-bg: rgba($black, $table-striped-bg-factor);

$table-active-color: $table-color;
$table-active-bg-factor: 0.1;
$table-active-bg: rgba($black, $table-active-bg-factor);

$table-hover-color: $table-color;
$table-hover-bg-factor: 0.075;
$table-hover-bg: rgba($black, $table-hover-bg-factor);

$table-border-factor: 0.1;
$table-border-width: var(--#{$prefix}border-width);
$table-border-color: var(--#{$prefix}border-color);

$table-striped-order: odd;
$table-striped-columns-order: even;

$table-group-separator-color: currentcolor;

$table-caption-color: $text-muted;

$table-bg-scale: -80%;
// scss-docs-end table-variables

// scss-docs-start table-loop
$table-variants: (
  "primary": shift-color($primary, $table-bg-scale),
  "secondary": shift-color($secondary, $table-bg-scale),
  "success": shift-color($success, $table-bg-scale),
  "info": shift-color($info, $table-bg-scale),
  "warning": shift-color($warning, $table-bg-scale),
  "danger": shift-color($danger, $table-bg-scale),
  "light": $light,
  "dark": $dark
);
// scss-docs-end table-loop

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

// scss-docs-start input-btn-variables
$input-btn-padding-y: 0.375rem;
$input-btn-padding-x: 1.25rem;
$input-btn-font-family: null;
$input-btn-font-size: $font-size-base;
$input-btn-line-height: $line-height-base;

$input-btn-focus-width: 0.25rem;
$input-btn-focus-color-opacity: 0.25;
$input-btn-focus-color: rgba($component-active-bg, $input-btn-focus-color-opacity);
$input-btn-focus-blur: 0;
$input-btn-focus-box-shadow: 0 0 $input-btn-focus-blur $input-btn-focus-width $input-btn-focus-color;

$input-btn-padding-y-sm: 0.25rem;
$input-btn-padding-x-sm: 0.5rem;
$input-btn-font-size-sm: $font-size-sm;

$input-btn-padding-y-lg: 0.5rem;
$input-btn-padding-x-lg: 1rem;
$input-btn-font-size-lg: $font-size-lg;

$input-btn-border-width: var(--#{$prefix}border-width);
// scss-docs-end input-btn-variables

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

// scss-docs-start btn-variables
$btn-color: var(--#{$prefix}body-color);
$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;
$btn-font-family: $input-btn-font-family;
$btn-font-size: $input-btn-font-size;
$btn-line-height: $input-btn-line-height;
$btn-white-space: null; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm: $input-btn-padding-y-sm;
$btn-padding-x-sm: $input-btn-padding-x-sm;
$btn-font-size-sm: $input-btn-font-size-sm;

$btn-padding-y-lg: $input-btn-padding-y-lg;
$btn-padding-x-lg: $input-btn-padding-x-lg;
$btn-font-size-lg: $input-btn-font-size-lg;

$btn-border-width: $input-btn-border-width;

$btn-font-weight: $font-weight-normal;
$btn-box-shadow: inset 0 1px 0 rgba($white, 0.15), 0 1px 1px rgba($black, 0.075);
$btn-focus-width: $input-btn-focus-width;
$btn-focus-box-shadow: $input-btn-focus-box-shadow;
$btn-disabled-opacity: 0.65;
$btn-active-box-shadow: inset 0 3px 5px rgba($black, 0.125);

$btn-link-color: var(--#{$prefix}link-color);
$btn-link-hover-color: var(--#{$prefix}link-hover-color);
$btn-link-disabled-color: $gray-600;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: var(--#{$prefix}border-radius);
$btn-border-radius-sm: var(--#{$prefix}border-radius-pill);
$btn-border-radius-lg: var(--#{$prefix}border-radius-lg);

$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out;

$btn-hover-bg-shade-amount: 15%;
$btn-hover-bg-tint-amount: 15%;
$btn-hover-border-shade-amount: 20%;
$btn-hover-border-tint-amount: 10%;
$btn-active-bg-shade-amount: 20%;
$btn-active-bg-tint-amount: 20%;
$btn-active-border-shade-amount: 25%;
$btn-active-border-tint-amount: 10%;
// scss-docs-end btn-variables

// Forms

// scss-docs-start form-text-variables
$form-text-margin-top: 0.25rem;
$form-text-font-size: $small-font-size;
$form-text-font-style: null;
$form-text-font-weight: null;
$form-text-color: var(--#{$prefix}secondary-color);
// scss-docs-end form-text-variables

// scss-docs-start form-label-variables
$form-label-margin-bottom: 0.5rem;
$form-label-font-size: null;
$form-label-font-style: null;
$form-label-font-weight: null;
$form-label-color: null;
// scss-docs-end form-label-variables

// scss-docs-start form-input-variables
$input-padding-y: $input-btn-padding-y;
$input-padding-x: $input-btn-padding-x;
$input-font-family: $input-btn-font-family;
$input-font-size: $input-btn-font-size;
$input-font-weight: $font-weight-base;
$input-line-height: $input-btn-line-height;

$input-padding-y-sm: $input-btn-padding-y-sm;
$input-padding-x-sm: $input-btn-padding-x-sm;
$input-font-size-sm: $input-btn-font-size-sm;

$input-padding-y-lg: $input-btn-padding-y-lg;
$input-padding-x-lg: $input-btn-padding-x-lg;
$input-font-size-lg: $input-btn-font-size-lg;

$input-bg: var(--#{$prefix}gray-100);
$input-disabled-color: null;
// $input-disabled-bg: var(--#{$prefix}secondary-bg);
$input-disabled-bg: var(--#{$prefix}gray-400);
$input-disabled-border-color: null;

$input-color: var(--#{$prefix}body-color);
$input-border-color: var(--#{$prefix}border-color);
$input-border-width: $input-btn-border-width;
$input-box-shadow: null;

$input-border-radius: var(--#{$prefix}border-radius);
$input-border-radius-sm: var(--#{$prefix}border-radius-pill);
$input-border-radius-lg: var(--#{$prefix}border-radius-lg);

$input-focus-bg: $input-bg;
$input-focus-border-color: tint-color($component-active-bg, 50%);
$input-focus-color: $input-color;
$input-focus-width: $input-btn-focus-width;
$input-focus-box-shadow: $input-btn-focus-box-shadow;

$input-placeholder-color: var(--#{$prefix}secondary-color);
$input-plaintext-color: var(--#{$prefix}body-color);

$input-height-border: calc(#{$input-border-width} * 2); // stylelint-disable-line function-disallowed-list

$input-height-inner: add($input-line-height * 1em, $input-padding-y * 2);
$input-height-inner-half: add($input-line-height * 0.5em, $input-padding-y);
$input-height-inner-quarter: add($input-line-height * 0.25em, $input-padding-y * 0.5);

$input-height: add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false));
$input-height-sm: add($input-line-height * 1em, add($input-padding-y-sm * 2, $input-height-border, false));
$input-height-lg: add($input-line-height * 1em, add($input-padding-y-lg * 2, $input-height-border, false));

$input-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

$form-color-width: 3rem;
// scss-docs-end form-input-variables

// scss-docs-start form-check-variables
$form-check-input-width: 1em;
$form-check-min-height: $font-size-base * $line-height-base;
$form-check-padding-start: $form-check-input-width +  0.5em;
$form-check-margin-bottom: 0.125rem;
$form-check-label-color: null;
$form-check-label-cursor: null;
$form-check-transition: null;

$form-check-input-active-filter: brightness(90%);

$form-check-input-bg: $input-bg;
$form-check-input-border: var(--#{$prefix}border-width) solid var(--#{$prefix}border-color);
$form-check-input-border-radius: 0.25em;
$form-check-radio-border-radius: 50%;
$form-check-input-focus-border: $input-focus-border-color;
$form-check-input-focus-box-shadow: $input-btn-focus-box-shadow;

$form-check-input-checked-color: $component-active-color;
$form-check-input-checked-bg-color: $component-active-bg;
$form-check-input-checked-border-color: $form-check-input-checked-bg-color;
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/></svg>");
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>");

$form-check-input-indeterminate-color: $component-active-color;
$form-check-input-indeterminate-bg-color: $component-active-bg;
$form-check-input-indeterminate-border-color: $form-check-input-indeterminate-bg-color;
$form-check-input-indeterminate-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-indeterminate-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>");

$form-check-input-disabled-opacity: 0.5;
$form-check-label-disabled-opacity: $form-check-input-disabled-opacity;
$form-check-btn-check-disabled-opacity: $btn-disabled-opacity;

$form-check-inline-margin-end: 1rem;
// scss-docs-end form-check-variables

// scss-docs-start form-switch-variables
$form-switch-color: rgba($black, 0.25);
$form-switch-width: 2em;
$form-switch-padding-start: $form-switch-width + 0.5em;
$form-switch-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color}'/></svg>");
$form-switch-border-radius: $form-switch-width;
$form-switch-transition: background-position 0.15s ease-in-out;

$form-switch-focus-color: $input-focus-border-color;
$form-switch-focus-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-focus-color}'/></svg>");

$form-switch-checked-color: $component-active-color;
$form-switch-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-checked-color}'/></svg>");
$form-switch-checked-bg-position: right center;
// scss-docs-end form-switch-variables

// scss-docs-start input-group-variables
$input-group-addon-padding-y: $input-padding-y;
$input-group-addon-padding-x: $input-padding-x;
$input-group-addon-font-weight: $input-font-weight;
$input-group-addon-color: $input-color;
$input-group-addon-bg: var(--#{$prefix}tertiary-bg);
$input-group-addon-border-color: $input-border-color;
// scss-docs-end input-group-variables

// scss-docs-start form-select-variables
$form-select-padding-y: $input-padding-y;
$form-select-padding-x: $input-padding-x;
$form-select-font-family: $input-font-family;
$form-select-font-size: $input-font-size;
$form-select-indicator-padding: $form-select-padding-x * 3; // Extra padding for background-image
$form-select-font-weight: $input-font-weight;
$form-select-line-height: $input-line-height;
$form-select-color: $input-color;
$form-select-bg: $input-bg;
$form-select-disabled-color: null;
$form-select-disabled-bg: $gray-300;
$form-select-disabled-border-color: $input-disabled-border-color;
$form-select-bg-position: right $form-select-padding-x center;
$form-select-bg-size: 16px 12px; // In pixels because image dimensions
$form-select-indicator-color: $gray-800;
$form-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/></svg>");

$form-select-feedback-icon-padding-end: $form-select-padding-x * 2.5 + $form-select-indicator-padding;
$form-select-feedback-icon-position: center right $form-select-indicator-padding;
$form-select-feedback-icon-size: $input-height-inner-half $input-height-inner-half;

$form-select-border-width: $input-border-width;
$form-select-border-color: $input-border-color;
$form-select-border-radius: $input-border-radius;
$form-select-box-shadow: null;

$form-select-focus-border-color: $input-focus-border-color;
$form-select-focus-width: $input-focus-width;
$form-select-focus-box-shadow: 0 0 0 $form-select-focus-width $input-btn-focus-color;

$form-select-padding-y-sm: $input-padding-y-sm;
$form-select-padding-x-sm: $input-padding-x-sm;
$form-select-font-size-sm: $input-font-size-sm;
$form-select-border-radius-sm: $input-border-radius-sm;

$form-select-padding-y-lg: $input-padding-y-lg;
$form-select-padding-x-lg: $input-padding-x-lg;
$form-select-font-size-lg: $input-font-size-lg;
$form-select-border-radius-lg: $input-border-radius-lg;

$form-select-transition: $input-transition;
// scss-docs-end form-select-variables

// scss-docs-start form-range-variables
$form-range-track-width: 100%;
$form-range-track-height: 0.5rem;
$form-range-track-cursor: pointer;
$form-range-track-bg: var(--#{$prefix}tertiary-bg);
$form-range-track-border-radius: 1rem;
$form-range-track-box-shadow: $box-shadow-inset;

$form-range-thumb-width: 1rem;
$form-range-thumb-height: $form-range-thumb-width;
$form-range-thumb-bg: $component-active-bg;
$form-range-thumb-border: 0;
$form-range-thumb-border-radius: 1rem;
$form-range-thumb-box-shadow: 0 0.1rem 0.25rem rgba($black, 0.1);
$form-range-thumb-focus-box-shadow: 0 0 0 1px $body-bg, $input-focus-box-shadow;
$form-range-thumb-focus-box-shadow-width: $input-focus-width; // For focus box shadow issue in Edge
$form-range-thumb-active-bg: tint-color($component-active-bg, 70%);
$form-range-thumb-disabled-bg: var(--#{$prefix}secondary-color);
$form-range-thumb-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out;
// scss-docs-end form-range-variables

// scss-docs-start form-file-variables
$form-file-button-color: $input-color;
$form-file-button-bg: var(--#{$prefix}tertiary-bg);
$form-file-button-hover-bg: var(--#{$prefix}secondary-bg);
// scss-docs-end form-file-variables

// scss-docs-start form-floating-variables
$form-floating-height: add(3.5rem, $input-height-border);
$form-floating-line-height: 1.25;
$form-floating-padding-x: $input-padding-x;
$form-floating-padding-y: 1rem;
$form-floating-input-padding-t: 1.625rem;
$form-floating-input-padding-b: 0.625rem;
$form-floating-label-height: 1.5em;
$form-floating-label-opacity: 0.65;
$form-floating-label-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
$form-floating-label-disabled-color: $gray-600;
$form-floating-transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
// scss-docs-end form-floating-variables

// Form validation

// scss-docs-start form-feedback-variables
$form-feedback-margin-top: $form-text-margin-top;
$form-feedback-font-size: $form-text-font-size;
$form-feedback-font-style: $form-text-font-style;
$form-feedback-valid-color: $success;
$form-feedback-invalid-color: $danger;

$form-feedback-icon-valid-color: $form-feedback-valid-color;
$form-feedback-icon-valid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>");
$form-feedback-icon-invalid-color: $form-feedback-invalid-color;
$form-feedback-icon-invalid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>");
// scss-docs-end form-feedback-variables

// scss-docs-start form-validation-colors
$form-valid-color: $form-feedback-valid-color;
$form-valid-border-color: $form-feedback-valid-color;
$form-invalid-color: $form-feedback-invalid-color;
$form-invalid-border-color: $form-feedback-invalid-color;
// scss-docs-end form-validation-colors

// scss-docs-start form-validation-states
$form-validation-states: (
  "valid": (
    "color": var(--#{$prefix}form-valid-color),
    "icon": $form-feedback-icon-valid,
    "tooltip-color": #fff,
    "tooltip-bg-color": var(--#{$prefix}success),
    "focus-box-shadow": 0 0 $input-btn-focus-blur $input-focus-width
      rgba(var(--#{$prefix}success-rgb), $input-btn-focus-color-opacity),
    "border-color": var(--#{$prefix}form-valid-border-color)
  ),
  "invalid": (
    "color": var(--#{$prefix}form-invalid-color),
    "icon": $form-feedback-icon-invalid,
    "tooltip-color": #fff,
    "tooltip-bg-color": var(--#{$prefix}danger),
    "focus-box-shadow": 0 0 $input-btn-focus-blur $input-focus-width
      rgba(var(--#{$prefix}danger-rgb), $input-btn-focus-color-opacity),
    "border-color": var(--#{$prefix}form-invalid-border-color)
  )
);
// scss-docs-end form-validation-states

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

// scss-docs-start zindex-stack
$zindex-dropdown: 1000;
$zindex-sticky: 1020;
$zindex-fixed: 1030;
$zindex-offcanvas-backdrop: 1040;
$zindex-offcanvas: 1045;
$zindex-modal-backdrop: 1050;
$zindex-modal: 1055;
$zindex-popover: 1070;
$zindex-tooltip: 1080;
$zindex-toast: 1090;
// scss-docs-end zindex-stack

// scss-docs-start zindex-levels-map
$zindex-levels: (
  n1: -1,
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9
);
// scss-docs-end zindex-levels-map

// Navs

// scss-docs-start nav-variables
$nav-link-padding-y: 0.5rem;
$nav-link-padding-x: 1rem;
$nav-link-font-size: null;
$nav-link-font-weight: null;
$nav-link-color: var(--#{$prefix}link-color);
$nav-link-hover-color: var(--#{$prefix}link-hover-color);
$nav-link-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
$nav-link-disabled-color: var(--#{$prefix}secondary-color);
$nav-link-focus-box-shadow: $focus-ring-box-shadow;

$nav-tabs-border-color: var(--#{$prefix}border-color);
$nav-tabs-border-width: var(--#{$prefix}border-width);
$nav-tabs-border-radius: var(--#{$prefix}border-radius);
$nav-tabs-link-hover-border-color: var(--#{$prefix}secondary-bg) var(--#{$prefix}secondary-bg) $nav-tabs-border-color;
$nav-tabs-link-active-color: var(--#{$prefix}emphasis-color);
$nav-tabs-link-active-bg: var(--#{$prefix}body-bg);
$nav-tabs-link-active-border-color: var(--#{$prefix}border-color) var(--#{$prefix}border-color) $nav-tabs-link-active-bg;

$nav-pills-border-radius: var(--#{$prefix}border-radius);
$nav-pills-link-active-color: $component-active-color;
$nav-pills-link-active-bg: $component-active-bg;

$nav-underline-gap: 1rem;
$nav-underline-border-width: 0.125rem;
$nav-underline-link-active-color: var(--#{$prefix}emphasis-color);
// scss-docs-end nav-variables

// Navbar

// scss-docs-start navbar-variables
$navbar-padding-y: null;
// $navbar-padding-y: $spacer * 0.5;
$navbar-padding-x: null;

$navbar-nav-link-padding-x: 1.5rem;

$navbar-brand-font-size: $font-size-lg;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height: $font-size-base * $line-height-base + $nav-link-padding-y * 2;
$navbar-brand-height: $navbar-brand-font-size * $line-height-base;
$navbar-brand-padding-y: ($nav-link-height - $navbar-brand-height) * 0.5;
$navbar-brand-margin-end: 1rem;

$navbar-toggler-padding-y: 0.25rem;
$navbar-toggler-padding-x: 0.75rem;
$navbar-toggler-font-size: $font-size-lg;
$navbar-toggler-border-radius: $btn-border-radius;
$navbar-toggler-focus-width: $btn-focus-width;
$navbar-toggler-transition: box-shadow 0.15s ease-in-out;

$navbar-light-color: rgba(var(--#{$prefix}emphasis-color-rgb), 0.65);
$navbar-light-hover-color: rgba(var(--#{$prefix}emphasis-color-rgb), 0.8);
$navbar-light-active-color: rgba(var(--#{$prefix}emphasis-color-rgb), 1);
$navbar-light-disabled-color: rgba(var(--#{$prefix}emphasis-color-rgb), 0.3);
$navbar-light-icon-color: rgba($body-color, 0.75);
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-light-icon-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-light-toggler-border-color: rgba(var(--#{$prefix}emphasis-color-rgb), 0.15);
$navbar-light-brand-color: $navbar-light-active-color;
$navbar-light-brand-hover-color: $navbar-light-active-color;
// scss-docs-end navbar-variables

// scss-docs-start navbar-dark-variables
$navbar-dark-color: rgba($white, 0.75);
$navbar-dark-hover-color: rgba($white, 0.95);
$navbar-dark-active-color: $white;
$navbar-dark-disabled-color: rgba($white, 0.35);
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-dark-toggler-border-color: rgba($white, 0.1);
$navbar-dark-brand-color: $navbar-dark-active-color;
$navbar-dark-brand-hover-color: $navbar-dark-active-color;
// scss-docs-end navbar-dark-variables

// Dropdowns
//
// Dropdown menu container and contents.

// scss-docs-start dropdown-variables
$dropdown-min-width: 10rem;
$dropdown-padding-x: 0;
$dropdown-padding-y: 0.5rem;
$dropdown-spacer: 0;
$dropdown-font-size: $font-size-base;
$dropdown-color: var(--#{$prefix}body-color);
//$dropdown-bg: var(--#{$prefix}body-bg);
$dropdown-bg: var(--#{$prefix}white);
$dropdown-border-color: var(--#{$prefix}border-color-translucent);
$dropdown-border-radius: var(--#{$prefix}border-radius);
$dropdown-border-width: var(--#{$prefix}border-width);
$dropdown-inner-border-radius: calc(
  #{$dropdown-border-radius} - #{$dropdown-border-width}
); // stylelint-disable-line function-disallowed-list
$dropdown-divider-bg: $dropdown-border-color;
$dropdown-divider-margin-y: $spacer * 0.5;
$dropdown-box-shadow: $box-shadow;

$dropdown-link-color: var(--#{$prefix}body-color);
$dropdown-link-hover-color: var(--#{$prefix}primary);
// $dropdown-link-hover-bg: var(--#{$prefix}tertiary-bg);
$dropdown-link-hover-bg: null;

$dropdown-link-active-color: $component-active-color;
$dropdown-link-active-bg: $component-active-bg;

$dropdown-link-disabled-color: var(--#{$prefix}tertiary-color);

$dropdown-item-padding-y: $spacer * 0.25;
$dropdown-item-padding-x: $spacer;

$dropdown-header-color: $gray-600;
$dropdown-header-padding-x: $dropdown-item-padding-x;
$dropdown-header-padding-y: $dropdown-padding-y;
// fusv-disable
$dropdown-header-padding: $dropdown-header-padding-y $dropdown-header-padding-x; // Deprecated in v5.2.0
// fusv-enable
// scss-docs-end dropdown-variables

// scss-docs-start dropdown-dark-variables
$dropdown-dark-color: $gray-300;
$dropdown-dark-bg: $gray-800;
$dropdown-dark-border-color: $dropdown-border-color;
$dropdown-dark-divider-bg: $dropdown-divider-bg;
$dropdown-dark-box-shadow: null;
$dropdown-dark-link-color: $dropdown-dark-color;
$dropdown-dark-link-hover-color: $white;
$dropdown-dark-link-hover-bg: rgba($white, 0.15);
$dropdown-dark-link-active-color: $dropdown-link-active-color;
$dropdown-dark-link-active-bg: $dropdown-link-active-bg;
$dropdown-dark-link-disabled-color: $gray-500;
$dropdown-dark-header-color: $gray-500;
// scss-docs-end dropdown-dark-variables

// Pagination

// scss-docs-start pagination-variables
$pagination-padding-y: 0.375rem;
$pagination-padding-x: 0.75rem;
$pagination-padding-y-sm: 0.25rem;
$pagination-padding-x-sm: 0.5rem;
$pagination-padding-y-lg: 0.75rem;
$pagination-padding-x-lg: 1.5rem;

$pagination-font-size: $font-size-base;

$pagination-color: var(--#{$prefix}link-color);
$pagination-bg: var(--#{$prefix}body-bg);
$pagination-border-radius: var(--#{$prefix}border-radius);
$pagination-border-width: var(--#{$prefix}border-width);
$pagination-margin-start: calc(#{$pagination-border-width} * -1); // stylelint-disable-line function-disallowed-list
$pagination-border-color: var(--#{$prefix}border-color);

$pagination-focus-color: var(--#{$prefix}link-hover-color);
$pagination-focus-bg: var(--#{$prefix}secondary-bg);
$pagination-focus-box-shadow: $focus-ring-box-shadow;
$pagination-focus-outline: 0;

$pagination-hover-color: var(--#{$prefix}link-hover-color);
$pagination-hover-bg: var(--#{$prefix}tertiary-bg);
$pagination-hover-border-color: var(--#{$prefix}border-color); // Todo in v6: remove this?

$pagination-active-color: $component-active-color;
$pagination-active-bg: $component-active-bg;
$pagination-active-border-color: $component-active-bg;

$pagination-disabled-color: var(--#{$prefix}secondary-color);
$pagination-disabled-bg: var(--#{$prefix}secondary-bg);
$pagination-disabled-border-color: var(--#{$prefix}border-color);

$pagination-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out;

$pagination-border-radius-sm: var(--#{$prefix}border-radius-sm);
$pagination-border-radius-lg: var(--#{$prefix}border-radius-lg);
// scss-docs-end pagination-variables

// Placeholders

// scss-docs-start placeholders
$placeholder-opacity-max: 0.5;
$placeholder-opacity-min: 0.2;
// scss-docs-end placeholders

// Cards

// scss-docs-start card-variables
$card-spacer-y: $spacer;
$card-spacer-x: $spacer;
$card-title-spacer-y: $spacer * 0.5;
$card-title-color: null;
$card-subtitle-color: null;
$card-border-width: var(--#{$prefix}border-width);
$card-border-color: var(--#{$prefix}border-color-translucent);
$card-border-radius: var(--#{$prefix}border-radius-xl);
$card-box-shadow: $box-shadow;
$card-inner-border-radius: subtract($card-border-radius, $card-border-width);
$card-cap-padding-y: $card-spacer-y * 0.5;
$card-cap-padding-x: $card-spacer-x;
$card-cap-bg: rgba(var(--#{$prefix}body-color-rgb), 0.03);
$card-cap-color: null;
$card-height: null;
$card-color: null;
$card-bg: var(--#{$prefix}body-bg);
$card-img-overlay-padding: $spacer;
$card-group-margin: $grid-gutter-width * 0.5;
// scss-docs-end card-variables

// Accordion

// scss-docs-start accordion-variables
$accordion-padding-y: 1rem;
$accordion-padding-x: 1.25rem;
$accordion-color: var(--#{$prefix}body-color);
$accordion-bg: transparent;
//$accordion-bg: var(--#{$prefix}body-bg);
$accordion-border-width: var(--#{$prefix}border-width);
$accordion-border-color: var(--#{$prefix}border-color);
$accordion-border-radius: var(--#{$prefix}border-radius-xl);
$accordion-inner-border-radius: subtract($accordion-border-radius, $accordion-border-width);

$accordion-body-padding-y: $accordion-padding-y;
$accordion-body-padding-x: $accordion-padding-x;

$accordion-button-padding-y: $accordion-padding-y;
$accordion-button-padding-x: $accordion-padding-x;
$accordion-button-color: var(--#{$prefix}body-color);
$accordion-button-bg: var(--#{$prefix}accordion-bg);
//$accordion-button-bg: var(--#{$prefix}accordion-bg);
$accordion-transition: $btn-transition, border-radius 0.15s ease;
$accordion-button-active-bg: var(--#{$prefix}dark-bg-subtle);
$accordion-button-active-color: var(--#{$prefix}primary-text-emphasis);

$accordion-button-focus-border-color: $input-focus-border-color;
$accordion-button-focus-box-shadow: $btn-focus-box-shadow;

$accordion-icon-width: 1.25rem;
$accordion-icon-color: $body-color;
$accordion-icon-active-color: $primary-text-emphasis;
$accordion-icon-transition: transform 0.2s ease-in-out;
$accordion-icon-transform: rotate(-180deg);

$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
$accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
// scss-docs-end accordion-variables

// Tooltips

// scss-docs-start tooltip-variables
$tooltip-font-size: $font-size-sm;
$tooltip-max-width: 200px;
$tooltip-color: var(--#{$prefix}body-bg);
$tooltip-bg: var(--#{$prefix}emphasis-color);
$tooltip-border-radius: var(--#{$prefix}border-radius);
$tooltip-opacity: 0.9;
$tooltip-padding-y: $spacer * 0.25;
$tooltip-padding-x: $spacer * 0.5;
$tooltip-margin: null; // TODO: remove this in v6

$tooltip-arrow-width: 0.8rem;
$tooltip-arrow-height: 0.4rem;
// fusv-disable
$tooltip-arrow-color: null; // Deprecated in Bootstrap 5.2.0 for CSS variables
// fusv-enable
// scss-docs-end tooltip-variables

// Form tooltips must come after regular tooltips
// scss-docs-start tooltip-feedback-variables
$form-feedback-tooltip-padding-y: $tooltip-padding-y;
$form-feedback-tooltip-padding-x: $tooltip-padding-x;
$form-feedback-tooltip-font-size: $tooltip-font-size;
$form-feedback-tooltip-line-height: null;
$form-feedback-tooltip-opacity: $tooltip-opacity;
$form-feedback-tooltip-border-radius: $tooltip-border-radius;
// scss-docs-end tooltip-feedback-variables

// Popovers

// scss-docs-start popover-variables
$popover-font-size: $font-size-sm;
$popover-bg: var(--#{$prefix}body-bg);
$popover-max-width: 276px;
$popover-border-width: var(--#{$prefix}border-width);
$popover-border-color: var(--#{$prefix}border-color-translucent);
$popover-border-radius: var(--#{$prefix}border-radius-lg);
$popover-inner-border-radius: calc(
  #{$popover-border-radius} - #{$popover-border-width}
); // stylelint-disable-line function-disallowed-list
$popover-box-shadow: $box-shadow;

$popover-header-font-size: $font-size-base;
$popover-header-bg: var(--#{$prefix}secondary-bg);
$popover-header-color: $headings-color;
$popover-header-padding-y: 0.5rem;
$popover-header-padding-x: $spacer;

$popover-body-color: var(--#{$prefix}body-color);
$popover-body-padding-y: $spacer;
$popover-body-padding-x: $spacer;

$popover-arrow-width: 1rem;
$popover-arrow-height: 0.5rem;
// scss-docs-end popover-variables

// fusv-disable
// Deprecated in Bootstrap 5.2.0 for CSS variables
$popover-arrow-color: $popover-bg;
$popover-arrow-outer-color: var(--#{$prefix}border-color-translucent);
// fusv-enable

// Toasts

// scss-docs-start toast-variables
$toast-max-width: 350px;
$toast-padding-x: 0.75rem;
$toast-padding-y: 0.5rem;
$toast-font-size: 0.875rem;
$toast-color: null;
$toast-background-color: rgba(var(--#{$prefix}body-bg-rgb), 0.85);
$toast-border-width: var(--#{$prefix}border-width);
$toast-border-color: var(--#{$prefix}border-color-translucent);
$toast-border-radius: var(--#{$prefix}border-radius);
$toast-box-shadow: var(--#{$prefix}box-shadow);
$toast-spacing: $container-padding-x;

$toast-header-color: var(--#{$prefix}secondary-color);
$toast-header-background-color: rgba(var(--#{$prefix}body-bg-rgb), 0.85);
$toast-header-border-color: $toast-border-color;
// scss-docs-end toast-variables

// Badges

// scss-docs-start badge-variables
$badge-font-size: 0.75em;
$badge-font-weight: $font-weight-bold;
$badge-color: $white;
$badge-padding-y: 0.35em;
$badge-padding-x: 0.65em;
$badge-border-radius: var(--#{$prefix}border-radius);
// scss-docs-end badge-variables

// Modals

// scss-docs-start modal-variables
$modal-inner-padding: $spacer;

$modal-footer-margin-between: 0.5rem;

$modal-dialog-margin: 0.5rem;
$modal-dialog-margin-y-sm-up: 1.75rem;

$modal-title-line-height: $line-height-base;

$modal-content-color: null;
$modal-content-bg: var(--#{$prefix}body-bg);
$modal-content-border-color: var(--#{$prefix}border-color-translucent);
$modal-content-border-width: var(--#{$prefix}border-width);
$modal-content-border-radius: var(--#{$prefix}border-radius-lg);
$modal-content-inner-border-radius: subtract($modal-content-border-radius, $modal-content-border-width);
$modal-content-box-shadow-xs: $box-shadow-sm;
$modal-content-box-shadow-sm-up: $box-shadow;

$modal-backdrop-bg: $black;
$modal-backdrop-opacity: 0.5;

$modal-header-border-color: var(--#{$prefix}border-color);
$modal-header-border-width: $modal-content-border-width;
$modal-header-padding-y: $modal-inner-padding;
$modal-header-padding-x: $modal-inner-padding;
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x; // Keep this for backwards compatibility

$modal-footer-bg: null;
$modal-footer-border-color: $modal-header-border-color;
$modal-footer-border-width: $modal-header-border-width;

$modal-sm: 300px;
$modal-md: 500px;
$modal-lg: 800px;
$modal-xl: 1140px;

$modal-fade-transform: translate(0, -50px);
$modal-show-transform: none;
$modal-transition: transform 0.3s ease-out;
$modal-scale-transform: scale(1.02);
// scss-docs-end modal-variables

// Alerts
//
// Define alert colors, border radius, and padding.

// scss-docs-start alert-variables
$alert-padding-y: $spacer;
$alert-padding-x: $spacer;
$alert-margin-bottom: 1rem;
$alert-border-radius: var(--#{$prefix}border-radius);
$alert-link-font-weight: $font-weight-bold;
$alert-border-width: var(--#{$prefix}border-width);
$alert-bg-scale: -80%;
$alert-border-scale: -70%;
$alert-color-scale: 40%;
$alert-dismissible-padding-r: $alert-padding-x * 3; // 3x covers width of x plus default padding on either side
// scss-docs-end alert-variables

// fusv-disable
$alert-bg-scale: -80%; // Deprecated in v5.2.0, to be removed in v6
$alert-border-scale: -70%; // Deprecated in v5.2.0, to be removed in v6
$alert-color-scale: 40%; // Deprecated in v5.2.0, to be removed in v6
// fusv-enable

// Progress bars

// scss-docs-start progress-variables
$progress-height: 1rem;
$progress-font-size: $font-size-base * 0.75;
$progress-bg: var(--#{$prefix}secondary-bg);
$progress-border-radius: var(--#{$prefix}border-radius);
$progress-box-shadow: var(--#{$prefix}box-shadow-inset);
$progress-bar-color: $white;
$progress-bar-bg: $primary;
$progress-bar-animation-timing: 1s linear infinite;
$progress-bar-transition: width 0.6s ease;
// scss-docs-end progress-variables

// List group

// scss-docs-start list-group-variables
$list-group-color: var(--#{$prefix}body-color);
$list-group-bg: var(--#{$prefix}body-bg);
$list-group-border-color: var(--#{$prefix}border-color);
$list-group-border-width: var(--#{$prefix}border-width);
$list-group-border-radius: var(--#{$prefix}border-radius);

$list-group-item-padding-y: $spacer * 0.5;
$list-group-item-padding-x: $spacer;
// fusv-disable
$list-group-item-bg-scale: -80%; // Deprecated in v5.3.0
$list-group-item-color-scale: 40%; // Deprecated in v5.3.0
// fusv-enable

$list-group-hover-bg: var(--#{$prefix}tertiary-bg);
$list-group-active-color: $component-active-color;
$list-group-active-bg: $component-active-bg;
$list-group-active-border-color: $list-group-active-bg;

$list-group-disabled-color: var(--#{$prefix}secondary-color);
$list-group-disabled-bg: $list-group-bg;

$list-group-action-color: var(--#{$prefix}secondary-color);
$list-group-action-hover-color: var(--#{$prefix}emphasis-color);

$list-group-action-active-color: var(--#{$prefix}body-color);
$list-group-action-active-bg: var(--#{$prefix}secondary-bg);
// scss-docs-end list-group-variables

// Image thumbnails

// scss-docs-start thumbnail-variables
$thumbnail-padding: 0.25rem;
$thumbnail-bg: var(--#{$prefix}body-bg);
$thumbnail-border-width: var(--#{$prefix}border-width);
$thumbnail-border-color: var(--#{$prefix}border-color);
$thumbnail-border-radius: var(--#{$prefix}border-radius);
$thumbnail-box-shadow: var(--#{$prefix}box-shadow-sm);
// scss-docs-end thumbnail-variables

// Figures

// scss-docs-start figure-variables
$figure-caption-font-size: $small-font-size;
$figure-caption-color: var(--#{$prefix}secondary-color);
// scss-docs-end figure-variables

// Breadcrumbs

// scss-docs-start breadcrumb-variables
$breadcrumb-font-size: null;
$breadcrumb-padding-y: 0;
$breadcrumb-padding-x: 0;
$breadcrumb-item-padding-x: 0.5rem;
$breadcrumb-margin-bottom: 1rem;
$breadcrumb-bg: null;
$breadcrumb-divider-color: var(--#{$prefix}secondary-color);
$breadcrumb-active-color: var(--#{$prefix}secondary-color);
$breadcrumb-divider: quote("/");
$breadcrumb-divider-flipped: $breadcrumb-divider;
$breadcrumb-border-radius: null;
// scss-docs-end breadcrumb-variables

// Carousel

// scss-docs-start carousel-variables
$carousel-control-color: $white;
$carousel-control-width: 15%;
$carousel-control-opacity: 0.5;
$carousel-control-hover-opacity: 0.9;
$carousel-control-transition: opacity 0.15s ease;

$carousel-indicator-width: 30px;
$carousel-indicator-height: 3px;
$carousel-indicator-hit-area-height: 10px;
$carousel-indicator-spacer: 3px;
$carousel-indicator-opacity: 0.5;
$carousel-indicator-active-bg: $white;
$carousel-indicator-active-opacity: 1;
$carousel-indicator-transition: opacity 0.6s ease;

$carousel-caption-width: 70%;
$carousel-caption-color: $white;
$carousel-caption-padding-y: 1.25rem;
$carousel-caption-spacer: 1.25rem;

$carousel-control-icon-width: 2rem;

$carousel-control-prev-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>");
$carousel-control-next-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>");

$carousel-transition-duration: 0.6s;
$carousel-transition: transform $carousel-transition-duration ease-in-out; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)
// scss-docs-end carousel-variables

// scss-docs-start carousel-dark-variables
$carousel-dark-indicator-active-bg: $black;
$carousel-dark-caption-color: $black;
$carousel-dark-control-icon-filter: invert(1) grayscale(100);
// scss-docs-end carousel-dark-variables

// Spinners

// scss-docs-start spinner-variables
$spinner-width: 2rem;
$spinner-height: $spinner-width;
$spinner-vertical-align: -0.125em;
$spinner-border-width: 0.25em;
$spinner-animation-speed: 0.75s;

$spinner-width-sm: 1rem;
$spinner-height-sm: $spinner-width-sm;
$spinner-border-width-sm: 0.2em;
// scss-docs-end spinner-variables

// Close

// scss-docs-start close-variables
$btn-close-width: 1em;
$btn-close-height: $btn-close-width;
$btn-close-padding-x: 0.25em;
$btn-close-padding-y: $btn-close-padding-x;
$btn-close-color: $black;
$btn-close-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$btn-close-color}'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>");
$btn-close-focus-shadow: $focus-ring-box-shadow;
$btn-close-opacity: 0.5;
$btn-close-hover-opacity: 0.75;
$btn-close-focus-opacity: 1;
$btn-close-disabled-opacity: 0.25;
$btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
// scss-docs-end close-variables

// Offcanvas

// scss-docs-start offcanvas-variables
$offcanvas-padding-y: $modal-inner-padding;
$offcanvas-padding-x: $modal-inner-padding;
$offcanvas-horizontal-width: calc(100vw - $spacer);
$offcanvas-vertical-height: calc(100vh - $spacer);
$offcanvas-transition-duration: 0.2s;
$offcanvas-border-color: $modal-content-border-color;
$offcanvas-border-width: $modal-content-border-width;
$offcanvas-title-line-height: $modal-title-line-height;
$offcanvas-bg-color: rgba(var(--#{$prefix}white-rgb), 0.8);
$offcanvas-color: var(--#{$prefix}body-color);
$offcanvas-box-shadow: null;
$offcanvas-backdrop-bg: null;
$offcanvas-backdrop-opacity: null;
// scss-docs-end offcanvas-variables

// Code

$code-font-size: $small-font-size;
$code-color: $pink;

$kbd-padding-y: 0.1875rem;
$kbd-padding-x: 0.375rem;
$kbd-font-size: $code-font-size;
$kbd-color: var(--#{$prefix}body-bg);
$kbd-bg: var(--#{$prefix}body-color);
$nested-kbd-font-weight: null; // Deprecated in v5.2.0, removing in v6

$pre-color: null;
