@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

// stylelint-disable-next-line scss/dollar-variable-default
$utilities: map-merge(
  $utilities,
  (
    "rounded-top-right-bottom-left": (
      property: border-top-right-radius border-bottom-left-radius,
      class: rounded-top-right-bottom-left,
      values: (
        null: var(--#{$prefix}border-radius),
        0: 0,
        1: var(--#{$prefix}border-radius-sm),
        2: var(--#{$prefix}border-radius),
        3: var(--#{$prefix}border-radius-lg),
        4: var(--#{$prefix}border-radius-xl),
        5: var(--#{$prefix}border-radius-xxl),
        circle: 50%,
        pill: var(--#{$prefix}border-radius-pill)
      )
    )
  )
);

@import "~bootstrap/scss/utilities/api";

.vh-100 {
  min-height: 100vh;
}