// Select
//
// Replaces the browser default select with a custom one, mostly pulled from
// https://primer.github.io/.

.form-select {
  &:disabled {
    background-color: var(--#{$prefix}gray-400);
    opacity: $form-check-input-disabled-opacity;
  }
}