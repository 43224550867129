//---------------------------------------------
//
//  Fonts
//
//    1. Loading Mixins
//    2. Loading Fonts
//
//---------------------------------------------

//---------------------------------------------
// 1. Loading Mixins
//---------------------------------------------

//---------------------------------------------
// 2. Loading Fonts
//---------------------------------------------

@font-face {
    font-family: Roboto;
    src: url("../fonts/RobotoFlex-VariableFont_GRAD,XTRA,YOPQ,YTAS,YTDE,YTFI,YTLC,YTUC,opsz,slnt,wdth,wght.ttf") format("truetype-variations");
    font-weight: normal;
    font-style: normal;
}

$ti-font-path: '/fonts';
@import "~@tabler/icons-webfont/dist/tabler-icons.scss";