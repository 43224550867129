.form-floating {

    > .form-control:focus,
    > .form-control:not(:placeholder-shown),
    > .form-control-plaintext,
    > .form-select {
      ~ label {
  
        &::after {
          background-color: transparent;
        }
      }
    }

    > .form-control:-webkit-autofill {
    > :disabled ~ label,
    > .form-control:disabled ~ label {
  
      &::after {
        background-color: transparent;
      }
    }
  }
}